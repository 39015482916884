import axios from "axios";

const userAuthAPI = axios.create({
    baseURL: "https://crowdfunding-coursework.biffr.com/auth-service/api",
    headers: {
        "Content-Type": "application/json",
    },
});

const postAPI = axios.create({
    baseURL: "https://crowdfunding-coursework.biffr.com/fundraiser-service/api",
    headers: {
        "Content-Type": "application/json",
    },
});

const commentAPI = axios.create({
    baseURL: "https://crowdfunding-coursework.biffr.com/comment-service/api",
    headers: {
        "Content-Type": "application/json",
    },
});

export { userAuthAPI, postAPI, commentAPI };
